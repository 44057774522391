/**
 * Liste des apps à afficher dans la ve conf
 */
 import { defines } from "../../router/defines.js";
 import { hasOneOfRole } from "../../service/role_service.js";


 export const tableListConf = [
    {
      name: "Contrat de travail",
      route : defines.confAppWorksContract.view.path,
      roles: [ defines.confAppWorksContract.view.roles]
    },
    //  {
    //    name: "Consulter / modifier la Database Globale",
    //    route : "/consultermodifierdg",
    //  },
    {
      name: "Avec > Établissements visibles",
      route: defines.confAvecEstablishment.view.path,
      roles: [ defines.confAvecEstablishment.view.roles]
    },    
    {
      name: "Avec > Super-Établissements visibles",
      route: defines.confAvecSuperEstablishment.view.path,
      roles: [ defines.confAvecSuperEstablishment.view.roles]
    },
    {
      name: "Avec > Sous-activités visibles",
      route: defines.confAvecSubactivity.view.path,
      roles: [ defines.confAvecSubactivity.view.roles]
    },
    {
      name: "Avec > Clé de labels par activités",
      route: defines.confAvecKeyLabelsActivities.table.path,
      roles: [ defines.confAvecKeyLabelsActivities.table.roles]
    },
    {
      name: "Avec > Labels par activités",
      route: defines.confAvecIndicators.view.path,
      roles: [ defines.confAvecIndicators.view.roles]
    },
    {
      name: "Avec > Applications métier par établissement",
      route: defines.confEstablishmentBusinessApp.table.path,
      roles: [ defines.confEstablishmentBusinessApp.table.roles]
    },
    // {
    //   name: "DGAUTH > user ihm",
    //   route: defines.dgauth.view.path,
    //   roles: [ defines.dgauth.view.roles ],
    // },
  
 ];



/** Retourne la liste des apps configurables dans l'ordre alphabétique */
export function getSortAscendantTable () {
  let table = [];

  // Parcours de toutes les tables et vérification du matching de rôles
  // puis ajout à la liste home des tables autorisées
  for (let item of tableListConf) {
    if (hasOneOfRole(item.roles)) {
      table.push(item);
    }
  }

  return table.sort(function(a, b) {
    return a.name.localeCompare(b.name);
  });
}


